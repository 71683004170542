.container {
  max-width: 1310px;
  width: 100%;
  margin: 0 auto;
}

@mixin centered-max-w ($w) {
  max-width: $w;
  width: 100%;
  margin: 0 auto;
}
