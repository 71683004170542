.container[data-v-0bcfc6] {
  max-width: 1310px;
  width: 100%;
  margin: 0 auto;
}

.header[data-v-0bcfc6] {
  color: #fff;
  background: #040f70;
}

.header-wrapper[data-v-0bcfc6] {
  max-width: 1100px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  margin: 0 auto;
  padding: 32px 15px;
  display: flex;
}

@media screen and (max-width: 770px) {
  .header-wrapper[data-v-0bcfc6] {
    flex-direction: column;
    align-items: flex-start;
  }
}

.logo[data-v-0bcfc6] {
  font-size: 2em;
  font-weight: 400;
  line-height: 38px;
}

.navigation[data-v-0bcfc6] {
  justify-content: center;
  display: flex;
}

@media screen and (max-width: 770px) {
  .navigation[data-v-0bcfc6] {
    justify-content: flex-start;
  }
}

.navigation-list[data-v-0bcfc6] {
  font-size: 1.5em;
  font-weight: 400;
  line-height: 29px;
  display: flex;
}

@media screen and (max-width: 540px) {
  .navigation-list[data-v-0bcfc6] {
    flex-direction: column;
  }
}

.navigation-list__item[data-v-0bcfc6] {
  margin-right: 60px;
}

.navigation-list__item[data-v-0bcfc6]:last-child {
  margin-right: 0;
}

.footer[data-v-3ff6bb] {
  width: 100vw;
  background-color: #0717a3;
  padding: 38px 0;
  position: relative;
  left: calc(50% - 50vw);
}

.footer__content[data-v-3ff6bb] {
  width: 65%;
  justify-content: space-around;
  margin: 0 auto;
  display: flex;
}

.footer__text[data-v-3ff6bb] {
  color: #d1d1d1;
  margin-bottom: 12px;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.55;
}

.footer__links[data-v-3ff6bb] {
  flex-direction: column;
  display: flex;
}

.footer__link[data-v-3ff6bb] {
  color: #fafafa;
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.55;
  text-decoration: none;
}

.footer__link-icon[data-v-3ff6bb] {
  width: 26px;
  height: 26px;
  object-fit: contain;
}

.footer__icons[data-v-3ff6bb] {
  align-items: center;
  display: flex;
}

.footer__icons .footer__link[data-v-3ff6bb]:not(:last-child) {
  margin-right: 10px;
}

@media (max-width: 640px) {
  .footer__links[data-v-3ff6bb]:not(:last-child) {
    margin-right: 30px;
  }

  .footer__text[data-v-3ff6bb], .footer__link[data-v-3ff6bb] {
    font-size: 16px;
  }

  .footer__link-icon[data-v-3ff6bb] {
    width: 23px;
    height: 23px;
  }
}

@media (max-width: 450px) {
  .footer[data-v-3ff6bb] {
    padding: 20px 0;
    font-size: 12px;
  }

  .footer__content[data-v-3ff6bb] {
    width: 100%;
    box-sizing: border-box;
    justify-content: center;
    padding: 0 16px;
  }

  .footer__links[data-v-3ff6bb]:not(:last-child) {
    margin-right: 20px;
  }

  .footer__link[data-v-3ff6bb], .footer__text[data-v-3ff6bb] {
    margin-bottom: 6px;
  }

  .footer__link-icon[data-v-3ff6bb] {
    width: 19px;
    height: 19px;
  }
}

.container[data-v-a5972d] {
  max-width: 1310px;
  width: 100%;
  margin: 0 auto;
}

.hero[data-v-a5972d] {
  background: #040f70;
}

.hero-wrapper[data-v-a5972d] {
  max-width: 970px;
  width: 100%;
  justify-content: space-between;
  gap: 15px;
  margin: 0 auto;
  padding: 32px 15px 90px;
  display: flex;
}

@media screen and (max-width: 920px) {
  .hero-wrapper[data-v-a5972d] {
    flex-direction: column;
    gap: 40px;
  }
}

.hero-left[data-v-a5972d] {
  max-width: 580px;
  width: 100%;
}

@media screen and (max-width: 920px) {
  .hero-left[data-v-a5972d] {
    max-width: 100%;
    flex-direction: column;
  }
}

.title[data-v-a5972d] {
  color: #fff;
  margin-bottom: 30px;
  font-size: 2.1875em;
  font-weight: 700;
  line-height: 42px;
}

.subtitle[data-v-a5972d] {
  color: #fff;
  margin-bottom: 30px;
  font-size: 1.8em;
  font-weight: 300;
  line-height: 42px;
}

.icons[data-v-a5972d] {
  grid-gap: 15px;
  grid-template-columns: repeat(8, 57px);
  display: grid;
}

@media screen and (max-width: 600px) {
  .icons[data-v-a5972d] {
    grid-template-columns: repeat(5, 57px);
    justify-content: center;
  }
}

@media screen and (max-width: 400px) {
  .icons[data-v-a5972d] {
    grid-template-columns: repeat(3, 57px);
    justify-content: center;
  }
}

.icons picture[data-v-a5972d] {
  justify-content: center;
  display: flex;
}

.icons picture img[data-v-a5972d] {
  width: 57px;
  height: 57px;
  object-fit: contain;
}

.form[data-v-a5972d] {
  max-width: 295px;
  width: 100%;
  background: #fff;
  border-radius: 50px;
  flex-direction: column;
  padding: 30px;
  display: flex;
}

@media screen and (max-width: 920px) {
  .form[data-v-a5972d] {
    max-width: 495px;
    width: 100%;
    margin: 0 auto;
  }
}

.form .form-title[data-v-a5972d] {
  text-align: center;
  color: #393939;
  margin-bottom: 25px;
  font-size: 1.5em;
  font-weight: 400;
  line-height: 29px;
}

.form input[data-v-a5972d] {
  color: gray;
  background: #faf9f9;
  border: 0;
  border-radius: 35px;
  margin-bottom: 10px;
  padding: 15px 22px;
  font-size: 1.125em;
  font-weight: 400;
  line-height: 22px;
}

.form .btn[data-v-a5972d] {
  text-align: center;
  color: #fff1f1;
  border: 0;
  border-radius: 35px;
  padding: 15px 22px;
  font-size: 1.25em;
  font-weight: 400;
  line-height: 24px;
}

.form .btn--blue[data-v-a5972d] {
  color: #fff1f1;
  background: #0717a3;
}

.form .btn--gray[data-v-a5972d] {
  color: #393939;
  background: #eee;
}

.form .separator[data-v-a5972d] {
  text-align: center;
  margin: 10px 0;
}

.container[data-v-ebc427] {
  max-width: 1310px;
  width: 100%;
  margin: 0 auto;
}

.solution[data-v-ebc427] {
  background: #fff;
}

.wrapper[data-v-ebc427] {
  max-width: 1230px;
  width: 100%;
  margin: 0 auto;
  padding: 80px 15px;
}

.title[data-v-ebc427] {
  text-align: center;
  color: #393939;
  margin-bottom: 57px;
  font-size: 2.1875em;
  font-weight: 500;
  line-height: 42px;
}

.list[data-v-ebc427] {
  gap: 45px;
  display: flex;
}

@media screen and (max-width: 820px) {
  .list[data-v-ebc427] {
    flex-direction: column;
  }
}

.list-item[data-v-ebc427] {
  color: #393939;
  background: #faf9f9;
  border-radius: 30px;
  flex: 1;
  padding: 30px;
}

.list-item .text[data-v-ebc427] {
  margin-bottom: 30px;
  font-size: 1.5em;
  font-weight: 300;
  line-height: 29px;
}

.list-item .description[data-v-ebc427] {
  font-size: 1.5em;
  font-weight: 400;
  line-height: 29px;
}

.container[data-v-63b214] {
  max-width: 1310px;
  width: 100%;
  margin: 0 auto;
}

.wrapper[data-v-63b214] {
  max-width: 1230px;
  width: 100%;
  margin: 0 auto;
  padding: 60px 15px 80px;
}

.title[data-v-63b214] {
  text-align: center;
  margin-bottom: 32px;
  font-size: 2.1875em;
  font-weight: 500;
  line-height: 42px;
}

.list[data-v-63b214] {
  align-items: center;
  gap: 35px;
  display: flex;
}

@media screen and (max-width: 1024px) {
  .list[data-v-63b214] {
    flex-direction: column;
  }
}

.list-item[data-v-63b214] {
  color: #393939;
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
}

.list-item a[data-v-63b214] {
  cursor: pointer;
  text-decoration: none;
}

.list-item picture[data-v-63b214] {
  max-width: -webkit-fit-content;
  max-width: fit-content;
}

.list-item_text[data-v-63b214] {
  background: #faf9f9;
  border-radius: 10px;
  flex: 1;
  padding: 20px 30px;
  position: relative;
}

.list-item_text[data-v-63b214]:after {
  content: "";
  width: 0;
  height: 0;
  clear: both;
  border-top: 20px solid #faf9f9;
  border-left: 20px solid #0000;
  border-right: 20px solid #0000;
  position: absolute;
  top: 50%;
  left: -30px;
  transform: translateY(-50%)rotate(90deg);
}

.list-item_text span[data-v-63b214] {
  color: #000;
}

.author[data-v-63b214] {
  color: #040f70;
  text-align: right;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
}

.container[data-v-c9dc94] {
  max-width: 1310px;
  width: 100%;
  margin: 0 auto;
}

.benefits[data-v-c9dc94] {
  background: #faf9f9;
}

.wrapper[data-v-c9dc94] {
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
  padding: 60px 15px 80px;
}

.title[data-v-c9dc94] {
  text-align: center;
  margin-bottom: 60px;
  font-size: 2.1875em;
  font-weight: 500;
  line-height: 42px;
}

.benefits-table[data-v-c9dc94] {
  text-align: center;
  font-size: 1.75em;
  font-weight: 400;
  overflow-x: auto;
}

.benefits-table table[data-v-c9dc94] {
  width: 100%;
  border-spacing: 0 22px;
  overflow-x: auto;
}

.benefits-table table th[data-v-c9dc94] {
  padding: 0 8px;
  font-weight: 400;
}

.benefits-table table td[data-v-c9dc94] {
  vertical-align: middle;
  padding: 10px 25px;
}

.benefits-table table td[data-v-c9dc94]:first-child {
  text-align: left;
  font-weight: 300;
}

.benefits-table table td[data-v-c9dc94]:nth-child(2) {
  font-weight: 300;
}

.benefits-table table td[data-v-c9dc94]:nth-child(3) {
  color: #0717a3;
}

.benefits-table .logo-wrapper[data-v-c9dc94] {
  justify-content: center;
  align-items: center;
  display: flex;
}

.benefits-table .logo-wrapper span[data-v-c9dc94] {
  color: #0717a3;
  margin-right: 12px;
  line-height: 34px;
}

.benefits-table .logo-wrapper img[data-v-c9dc94] {
  width: 52px;
  height: 52px;
}

.benefits-table .done-icon img[data-v-c9dc94] {
  height: 40px;
  width: 40px;
  object-fit: contain;
}

.benefits-table .close-icon img[data-v-c9dc94] {
  height: 29px;
  width: 29px;
  object-fit: contain;
}

.with-dot[data-v-c9dc94] {
  display: list-item;
}

.container[data-v-8f0f9a] {
  max-width: 1310px;
  width: 100%;
  margin: 0 auto;
}

.fundamentals[data-v-8f0f9a] {
  background-color: #fff;
}

.wrapper[data-v-8f0f9a] {
  max-width: 1060px;
  width: 100%;
  margin: 0 auto;
  padding: 70px 15px 50px;
}

.title[data-v-8f0f9a] {
  text-align: center;
  margin-bottom: 90px;
  font-size: 2.1875em;
  font-weight: 500;
  line-height: 42px;
}

.list[data-v-8f0f9a] {
  grid-template-columns: repeat(3, 3fr);
  gap: 70px;
  display: grid;
}

@media screen and (max-width: 870px) {
  .list[data-v-8f0f9a] {
    grid-template-columns: repeat(2, 3fr);
  }
}

@media screen and (max-width: 770px) {
  .list[data-v-8f0f9a] {
    grid-template-columns: repeat(1, 3fr);
  }
}

.list-item .header[data-v-8f0f9a] {
  align-items: center;
  margin-bottom: 15px;
  display: flex;
}

.list-item .header picture[data-v-8f0f9a] {
  display: flex;
}

.list-item .header picture img[data-v-8f0f9a] {
  width: 80px;
  object-fit: contain;
}

.list-item .header .title[data-v-8f0f9a] {
  text-align: left;
  color: #393939;
  margin-bottom: 0;
  margin-left: 15px;
  font-size: 1.5em;
  font-weight: 500;
  line-height: 29px;
}

.list-item .description[data-v-8f0f9a] {
  color: #393939;
  font-size: 1.5em;
  font-weight: 300;
  line-height: 29px;
}

.container[data-v-c798d3] {
  max-width: 1310px;
  width: 100%;
  margin: 0 auto;
}

.chains[data-v-c798d3] {
  background: #faf9f9;
}

.wrapper[data-v-c798d3] {
  max-width: 1180px;
  width: 100%;
  margin: 0 auto;
  padding: 70px 15px 25px;
}

.title[data-v-c798d3] {
  text-align: center;
  color: #393939;
  margin-bottom: 15px;
  font-size: 2.1875em;
  font-weight: 500;
  line-height: 42px;
}

.sla[data-v-c798d3] {
  color: #393939;
  text-align: center;
  margin: 0 0 70px;
  font-size: 2.1875em;
  font-weight: 400;
  line-height: 42px;
}

.list[data-v-c798d3] {
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  margin-bottom: 40px;
  display: grid;
}

@media screen and (max-width: 870px) {
  .list[data-v-c798d3] {
    grid-template-columns: repeat(3, 4fr);
    gap: 30px;
  }
}

@media screen and (max-width: 700px) {
  .list[data-v-c798d3] {
    grid-template-columns: repeat(2, 4fr);
    gap: 15px;
  }
}

@media screen and (max-width: 570px) {
  .list[data-v-c798d3] {
    grid-template-columns: repeat(1, 4fr);
    gap: 15px;
  }
}

.list-item[data-v-c798d3] {
  background: #fff;
  border-radius: 50px;
  flex-direction: column;
  padding: 15px;
  display: flex;
}

.list-item .icon[data-v-c798d3] {
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  display: flex;
}

.list-item .icon picture[data-v-c798d3] {
  display: flex;
}

.list-item .icon picture img[data-v-c798d3] {
  width: 70px;
  object-fit: contain;
}

.list-item .name[data-v-c798d3] {
  text-align: center;
  color: #393939;
  margin-bottom: 15px;
  font-size: 1.3em;
  font-weight: 500;
  line-height: 27px;
}

.list-item .info[data-v-c798d3] {
  flex-grow: 1;
  margin-bottom: 15px;
  display: flex;
}

.list-item .info .type-col[data-v-c798d3], .list-item .info .api-col[data-v-c798d3] {
  flex: 1;
}

.list-item .type[data-v-c798d3] {
  text-align: center;
  color: #0717a3;
  margin-bottom: 10px;
  font-size: 1.25em;
  font-weight: 400;
  line-height: 24px;
}

.list-item .value[data-v-c798d3] {
  text-align: center;
  color: #393939;
  font-size: 1em;
  font-weight: 300;
  line-height: 19px;
}

.list-item .actions[data-v-c798d3] {
  text-align: center;
}

.list-item .actions a[data-v-c798d3] {
  text-align: center;
  color: #0717a3;
  text-transform: uppercase;
  background: #faf9f9;
  border: 0;
  border-radius: 15px;
  padding: 15px 10px;
  font-size: 1em;
  font-weight: 600;
  line-height: 21px;
  display: inline-block;
}

.info[data-v-c798d3] {
  text-align: left;
  color: #393939;
  font-size: .875em;
  font-style: normal;
  font-weight: 300;
  line-height: 17px;
}

.info a[data-v-c798d3] {
  color: #0717a3;
}

.container[data-v-d998d3] {
  max-width: 1310px;
  width: 100%;
  margin: 0 auto;
}

.pricing[data-v-d998d3] {
  background: #fff;
}

.wrapper[data-v-d998d3] {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 50px 15px;
}

.title[data-v-d998d3] {
  color: #393939;
  text-align: center;
  margin-bottom: 70px;
  font-size: 2.1875em;
  font-weight: 500;
  line-height: 42px;
}

.pricing-table[data-v-d998d3] {
  text-align: center;
  margin-bottom: 57px;
}

.pricing-table table[data-v-d998d3] {
  border-collapse: separate;
  border-spacing: 30px 0;
  display: flex;
  overflow-x: auto;
}

.pricing-table tbody[data-v-d998d3] {
  width: 100%;
  flex-grow: 1;
}

.pricing-table img[data-v-d998d3] {
  width: 36px;
  height: 36px;
  object-fit: contain;
}

.pricing-table tr:first-child th[data-v-d998d3]:not(:first-child), .pricing-table tr:first-child td[data-v-d998d3]:not(:first-child) {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.pricing-table tr:nth-child(2) td[data-v-d998d3]:not(:first-child) {
  text-align: center;
  color: #393939;
  font-size: 1.5em;
  font-weight: 400;
  line-height: 29px;
}

.pricing-table tr:last-child th[data-v-d998d3]:not(:first-child), .pricing-table tr:last-child td[data-v-d998d3]:not(:first-child) {
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.pricing-table th[data-v-d998d3] {
  text-align: center;
  color: #0717a3;
  padding: 30px;
  font-size: 1.6em;
  font-weight: 500;
  line-height: 24px;
}

.pricing-table th .light[data-v-d998d3] {
  text-align: center;
  font-size: .875em;
  font-weight: 300;
  line-height: 17px;
}

.pricing-table th[data-v-d998d3]:first-child {
  padding: 30px 30px 30px 0;
}

.pricing-table th[data-v-d998d3]:not(:first-child) {
  background: #faf9f9;
}

.pricing-table td[data-v-d998d3] {
  padding: 10px 30px;
}

.pricing-table td[data-v-d998d3]:first-child {
  text-align: left;
  color: #393939;
  padding: 10px 30px 10px 0;
  font-size: 1.125em;
  font-weight: 300;
  line-height: 22px;
}

.pricing-table td[data-v-d998d3]:not(:first-child) {
  text-align: center;
  color: #393939;
  background-color: #faf9f9;
  font-size: 1em;
  font-weight: 400;
  line-height: 17px;
}

.pricing-table td a[data-v-d998d3] {
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  background: #676767;
  border: 0;
  border-radius: 40px;
  margin-bottom: 20px;
  padding: 14px 48px;
  font-size: 1.25em;
  font-weight: 500;
  line-height: 24px;
  display: inline-block;
}

.info[data-v-d998d3] {
  color: #5c5c5c;
  flex-direction: column;
  gap: 12px;
  font-size: 1em;
  font-weight: 400;
  line-height: 19px;
  display: flex;
}

.b-500 b[data-v-d998d3] {
  font-weight: 500;
}

.container[data-v-3daae3] {
  max-width: 1310px;
  width: 100%;
  margin: 0 auto;
}

.footer[data-v-3daae3] {
  background: #faf9f9;
}

.wrapper[data-v-3daae3] {
  max-width: 1140px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  margin: 0 auto;
  padding: 90px 15px;
  display: flex;
}

@media screen and (max-width: 870px) {
  .wrapper[data-v-3daae3] {
    flex-direction: column;
  }
}

.text[data-v-3daae3] {
  text-align: center;
  color: #0717a3;
  font-size: 1.75em;
  font-weight: 500;
  line-height: 34px;
}

.action a[data-v-3daae3] {
  text-align: center;
  color: #fcfcff;
  background: #0717a3;
  border: 0;
  border-radius: 40px;
  padding: 20px 70px;
  font-size: 1.875em;
  font-weight: 500;
  line-height: 36px;
  display: inline-block;
}

.container {
  max-width: 1310px;
  width: 100%;
  margin: 0 auto;
}

:root {
  --base-font-size: 16px;
}

@media screen and (max-width: 700px) {
  :root {
    --base-font-size: 14px;
  }
}

@media screen and (max-width: 540px) {
  :root {
    --base-font-size: 12px;
  }
}

* {
  box-sizing: border-box;
  outline: none;
}

html, body {
  font-family: Inter, sans-serif;
  font-size: var(--base-font-size);
  color: #393939;
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

ul, ol {
  margin: 0;
  padding: 0;
}

ul li, ol li {
  list-style-type: none;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  cursor: pointer;
}

.container[data-v-0bcfc6] {
  max-width: 1310px;
  width: 100%;
  margin: 0 auto;
}

.header[data-v-0bcfc6] {
  color: #fff;
  background: #040f70;
}

.header-wrapper[data-v-0bcfc6] {
  max-width: 1100px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  margin: 0 auto;
  padding: 32px 15px;
  display: flex;
}

@media screen and (max-width: 770px) {
  .header-wrapper[data-v-0bcfc6] {
    flex-direction: column;
    align-items: flex-start;
  }
}

.logo[data-v-0bcfc6] {
  font-size: 2em;
  font-weight: 400;
  line-height: 38px;
}

.navigation[data-v-0bcfc6] {
  justify-content: center;
  display: flex;
}

@media screen and (max-width: 770px) {
  .navigation[data-v-0bcfc6] {
    justify-content: flex-start;
  }
}

.navigation-list[data-v-0bcfc6] {
  font-size: 1.5em;
  font-weight: 400;
  line-height: 29px;
  display: flex;
}

@media screen and (max-width: 540px) {
  .navigation-list[data-v-0bcfc6] {
    flex-direction: column;
  }
}

.navigation-list__item[data-v-0bcfc6] {
  margin-right: 60px;
}

.navigation-list__item[data-v-0bcfc6]:last-child {
  margin-right: 0;
}

.footer[data-v-3ff6bb] {
  width: 100vw;
  background-color: #0717a3;
  padding: 38px 0;
  position: relative;
  left: calc(50% - 50vw);
}

.footer__content[data-v-3ff6bb] {
  width: 65%;
  justify-content: space-around;
  margin: 0 auto;
  display: flex;
}

.footer__text[data-v-3ff6bb] {
  color: #d1d1d1;
  margin-bottom: 12px;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.55;
}

.footer__links[data-v-3ff6bb] {
  flex-direction: column;
  display: flex;
}

.footer__link[data-v-3ff6bb] {
  color: #fafafa;
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.55;
  text-decoration: none;
}

.footer__link-icon[data-v-3ff6bb] {
  width: 26px;
  height: 26px;
  object-fit: contain;
}

.footer__icons[data-v-3ff6bb] {
  align-items: center;
  display: flex;
}

.footer__icons .footer__link[data-v-3ff6bb]:not(:last-child) {
  margin-right: 10px;
}

@media (max-width: 640px) {
  .footer__links[data-v-3ff6bb]:not(:last-child) {
    margin-right: 30px;
  }

  .footer__text[data-v-3ff6bb], .footer__link[data-v-3ff6bb] {
    font-size: 16px;
  }

  .footer__link-icon[data-v-3ff6bb] {
    width: 23px;
    height: 23px;
  }
}

@media (max-width: 450px) {
  .footer[data-v-3ff6bb] {
    padding: 20px 0;
    font-size: 12px;
  }

  .footer__content[data-v-3ff6bb] {
    width: 100%;
    box-sizing: border-box;
    justify-content: center;
    padding: 0 16px;
  }

  .footer__links[data-v-3ff6bb]:not(:last-child) {
    margin-right: 20px;
  }

  .footer__link[data-v-3ff6bb], .footer__text[data-v-3ff6bb] {
    margin-bottom: 6px;
  }

  .footer__link-icon[data-v-3ff6bb] {
    width: 19px;
    height: 19px;
  }
}

.container[data-v-a5972d] {
  max-width: 1310px;
  width: 100%;
  margin: 0 auto;
}

.hero[data-v-a5972d] {
  background: #040f70;
}

.hero-wrapper[data-v-a5972d] {
  max-width: 970px;
  width: 100%;
  justify-content: space-between;
  gap: 15px;
  margin: 0 auto;
  padding: 32px 15px 90px;
  display: flex;
}

@media screen and (max-width: 920px) {
  .hero-wrapper[data-v-a5972d] {
    flex-direction: column;
    gap: 40px;
  }
}

.hero-left[data-v-a5972d] {
  max-width: 580px;
  width: 100%;
}

@media screen and (max-width: 920px) {
  .hero-left[data-v-a5972d] {
    max-width: 100%;
    flex-direction: column;
  }
}

.title[data-v-a5972d] {
  color: #fff;
  margin-bottom: 30px;
  font-size: 2.1875em;
  font-weight: 700;
  line-height: 42px;
}

.subtitle[data-v-a5972d] {
  color: #fff;
  margin-bottom: 30px;
  font-size: 1.8em;
  font-weight: 300;
  line-height: 42px;
}

.icons[data-v-a5972d] {
  grid-gap: 15px;
  grid-template-columns: repeat(8, 57px);
  display: grid;
}

@media screen and (max-width: 600px) {
  .icons[data-v-a5972d] {
    grid-template-columns: repeat(5, 57px);
    justify-content: center;
  }
}

@media screen and (max-width: 400px) {
  .icons[data-v-a5972d] {
    grid-template-columns: repeat(3, 57px);
    justify-content: center;
  }
}

.icons picture[data-v-a5972d] {
  justify-content: center;
  display: flex;
}

.icons picture img[data-v-a5972d] {
  width: 57px;
  height: 57px;
  object-fit: contain;
}

.form[data-v-a5972d] {
  max-width: 295px;
  width: 100%;
  background: #fff;
  border-radius: 50px;
  flex-direction: column;
  padding: 30px;
  display: flex;
}

@media screen and (max-width: 920px) {
  .form[data-v-a5972d] {
    max-width: 495px;
    width: 100%;
    margin: 0 auto;
  }
}

.form .form-title[data-v-a5972d] {
  text-align: center;
  color: #393939;
  margin-bottom: 25px;
  font-size: 1.5em;
  font-weight: 400;
  line-height: 29px;
}

.form input[data-v-a5972d] {
  color: gray;
  background: #faf9f9;
  border: 0;
  border-radius: 35px;
  margin-bottom: 10px;
  padding: 15px 22px;
  font-size: 1.125em;
  font-weight: 400;
  line-height: 22px;
}

.form .btn[data-v-a5972d] {
  text-align: center;
  color: #fff1f1;
  border: 0;
  border-radius: 35px;
  padding: 15px 22px;
  font-size: 1.25em;
  font-weight: 400;
  line-height: 24px;
}

.form .btn--blue[data-v-a5972d] {
  color: #fff1f1;
  background: #0717a3;
}

.form .btn--gray[data-v-a5972d] {
  color: #393939;
  background: #eee;
}

.form .separator[data-v-a5972d] {
  text-align: center;
  margin: 10px 0;
}

.container[data-v-ebc427] {
  max-width: 1310px;
  width: 100%;
  margin: 0 auto;
}

.solution[data-v-ebc427] {
  background: #fff;
}

.wrapper[data-v-ebc427] {
  max-width: 1230px;
  width: 100%;
  margin: 0 auto;
  padding: 80px 15px;
}

.title[data-v-ebc427] {
  text-align: center;
  color: #393939;
  margin-bottom: 57px;
  font-size: 2.1875em;
  font-weight: 500;
  line-height: 42px;
}

.list[data-v-ebc427] {
  gap: 45px;
  display: flex;
}

@media screen and (max-width: 820px) {
  .list[data-v-ebc427] {
    flex-direction: column;
  }
}

.list-item[data-v-ebc427] {
  color: #393939;
  background: #faf9f9;
  border-radius: 30px;
  flex: 1;
  padding: 30px;
}

.list-item .text[data-v-ebc427] {
  margin-bottom: 30px;
  font-size: 1.5em;
  font-weight: 300;
  line-height: 29px;
}

.list-item .description[data-v-ebc427] {
  font-size: 1.5em;
  font-weight: 400;
  line-height: 29px;
}

.container[data-v-63b214] {
  max-width: 1310px;
  width: 100%;
  margin: 0 auto;
}

.wrapper[data-v-63b214] {
  max-width: 1230px;
  width: 100%;
  margin: 0 auto;
  padding: 60px 15px 80px;
}

.title[data-v-63b214] {
  text-align: center;
  margin-bottom: 32px;
  font-size: 2.1875em;
  font-weight: 500;
  line-height: 42px;
}

.list[data-v-63b214] {
  align-items: center;
  gap: 35px;
  display: flex;
}

@media screen and (max-width: 1024px) {
  .list[data-v-63b214] {
    flex-direction: column;
  }
}

.list-item[data-v-63b214] {
  color: #393939;
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
}

.list-item a[data-v-63b214] {
  cursor: pointer;
  text-decoration: none;
}

.list-item picture[data-v-63b214] {
  max-width: -webkit-fit-content;
  max-width: fit-content;
}

.list-item_text[data-v-63b214] {
  background: #faf9f9;
  border-radius: 10px;
  flex: 1;
  padding: 20px 30px;
  position: relative;
}

.list-item_text[data-v-63b214]:after {
  content: "";
  width: 0;
  height: 0;
  clear: both;
  border-top: 20px solid #faf9f9;
  border-left: 20px solid #0000;
  border-right: 20px solid #0000;
  position: absolute;
  top: 50%;
  left: -30px;
  transform: translateY(-50%)rotate(90deg);
}

.list-item_text span[data-v-63b214] {
  color: #000;
}

.author[data-v-63b214] {
  color: #040f70;
  text-align: right;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
}

.container[data-v-c9dc94] {
  max-width: 1310px;
  width: 100%;
  margin: 0 auto;
}

.benefits[data-v-c9dc94] {
  background: #faf9f9;
}

.wrapper[data-v-c9dc94] {
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
  padding: 60px 15px 80px;
}

.title[data-v-c9dc94] {
  text-align: center;
  margin-bottom: 60px;
  font-size: 2.1875em;
  font-weight: 500;
  line-height: 42px;
}

.benefits-table[data-v-c9dc94] {
  text-align: center;
  font-size: 1.75em;
  font-weight: 400;
  overflow-x: auto;
}

.benefits-table table[data-v-c9dc94] {
  width: 100%;
  border-spacing: 0 22px;
  overflow-x: auto;
}

.benefits-table table th[data-v-c9dc94] {
  padding: 0 8px;
  font-weight: 400;
}

.benefits-table table td[data-v-c9dc94] {
  vertical-align: middle;
  padding: 10px 25px;
}

.benefits-table table td[data-v-c9dc94]:first-child {
  text-align: left;
  font-weight: 300;
}

.benefits-table table td[data-v-c9dc94]:nth-child(2) {
  font-weight: 300;
}

.benefits-table table td[data-v-c9dc94]:nth-child(3) {
  color: #0717a3;
}

.benefits-table .logo-wrapper[data-v-c9dc94] {
  justify-content: center;
  align-items: center;
  display: flex;
}

.benefits-table .logo-wrapper span[data-v-c9dc94] {
  color: #0717a3;
  margin-right: 12px;
  line-height: 34px;
}

.benefits-table .logo-wrapper img[data-v-c9dc94] {
  width: 52px;
  height: 52px;
}

.benefits-table .done-icon img[data-v-c9dc94] {
  height: 40px;
  width: 40px;
  object-fit: contain;
}

.benefits-table .close-icon img[data-v-c9dc94] {
  height: 29px;
  width: 29px;
  object-fit: contain;
}

.with-dot[data-v-c9dc94] {
  display: list-item;
}

.container[data-v-8f0f9a] {
  max-width: 1310px;
  width: 100%;
  margin: 0 auto;
}

.fundamentals[data-v-8f0f9a] {
  background-color: #fff;
}

.wrapper[data-v-8f0f9a] {
  max-width: 1060px;
  width: 100%;
  margin: 0 auto;
  padding: 70px 15px 50px;
}

.title[data-v-8f0f9a] {
  text-align: center;
  margin-bottom: 90px;
  font-size: 2.1875em;
  font-weight: 500;
  line-height: 42px;
}

.list[data-v-8f0f9a] {
  grid-template-columns: repeat(3, 3fr);
  gap: 70px;
  display: grid;
}

@media screen and (max-width: 870px) {
  .list[data-v-8f0f9a] {
    grid-template-columns: repeat(2, 3fr);
  }
}

@media screen and (max-width: 770px) {
  .list[data-v-8f0f9a] {
    grid-template-columns: repeat(1, 3fr);
  }
}

.list-item .header[data-v-8f0f9a] {
  align-items: center;
  margin-bottom: 15px;
  display: flex;
}

.list-item .header picture[data-v-8f0f9a] {
  display: flex;
}

.list-item .header picture img[data-v-8f0f9a] {
  width: 80px;
  object-fit: contain;
}

.list-item .header .title[data-v-8f0f9a] {
  text-align: left;
  color: #393939;
  margin-bottom: 0;
  margin-left: 15px;
  font-size: 1.5em;
  font-weight: 500;
  line-height: 29px;
}

.list-item .description[data-v-8f0f9a] {
  color: #393939;
  font-size: 1.5em;
  font-weight: 300;
  line-height: 29px;
}

.container[data-v-c798d3] {
  max-width: 1310px;
  width: 100%;
  margin: 0 auto;
}

.chains[data-v-c798d3] {
  background: #faf9f9;
}

.wrapper[data-v-c798d3] {
  max-width: 1180px;
  width: 100%;
  margin: 0 auto;
  padding: 70px 15px 25px;
}

.title[data-v-c798d3] {
  text-align: center;
  color: #393939;
  margin-bottom: 15px;
  font-size: 2.1875em;
  font-weight: 500;
  line-height: 42px;
}

.sla[data-v-c798d3] {
  color: #393939;
  text-align: center;
  margin: 0 0 70px;
  font-size: 2.1875em;
  font-weight: 400;
  line-height: 42px;
}

.list[data-v-c798d3] {
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  margin-bottom: 40px;
  display: grid;
}

@media screen and (max-width: 870px) {
  .list[data-v-c798d3] {
    grid-template-columns: repeat(3, 4fr);
    gap: 30px;
  }
}

@media screen and (max-width: 700px) {
  .list[data-v-c798d3] {
    grid-template-columns: repeat(2, 4fr);
    gap: 15px;
  }
}

@media screen and (max-width: 570px) {
  .list[data-v-c798d3] {
    grid-template-columns: repeat(1, 4fr);
    gap: 15px;
  }
}

.list-item[data-v-c798d3] {
  background: #fff;
  border-radius: 50px;
  flex-direction: column;
  padding: 15px;
  display: flex;
}

.list-item .icon[data-v-c798d3] {
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  display: flex;
}

.list-item .icon picture[data-v-c798d3] {
  display: flex;
}

.list-item .icon picture img[data-v-c798d3] {
  width: 70px;
  object-fit: contain;
}

.list-item .name[data-v-c798d3] {
  text-align: center;
  color: #393939;
  margin-bottom: 15px;
  font-size: 1.3em;
  font-weight: 500;
  line-height: 27px;
}

.list-item .info[data-v-c798d3] {
  flex-grow: 1;
  margin-bottom: 15px;
  display: flex;
}

.list-item .info .type-col[data-v-c798d3], .list-item .info .api-col[data-v-c798d3] {
  flex: 1;
}

.list-item .type[data-v-c798d3] {
  text-align: center;
  color: #0717a3;
  margin-bottom: 10px;
  font-size: 1.25em;
  font-weight: 400;
  line-height: 24px;
}

.list-item .value[data-v-c798d3] {
  text-align: center;
  color: #393939;
  font-size: 1em;
  font-weight: 300;
  line-height: 19px;
}

.list-item .actions[data-v-c798d3] {
  text-align: center;
}

.list-item .actions a[data-v-c798d3] {
  text-align: center;
  color: #0717a3;
  text-transform: uppercase;
  background: #faf9f9;
  border: 0;
  border-radius: 15px;
  padding: 15px 10px;
  font-size: 1em;
  font-weight: 600;
  line-height: 21px;
  display: inline-block;
}

.info[data-v-c798d3] {
  text-align: left;
  color: #393939;
  font-size: .875em;
  font-style: normal;
  font-weight: 300;
  line-height: 17px;
}

.info a[data-v-c798d3] {
  color: #0717a3;
}

.container[data-v-d998d3] {
  max-width: 1310px;
  width: 100%;
  margin: 0 auto;
}

.pricing[data-v-d998d3] {
  background: #fff;
}

.wrapper[data-v-d998d3] {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 50px 15px;
}

.title[data-v-d998d3] {
  color: #393939;
  text-align: center;
  margin-bottom: 70px;
  font-size: 2.1875em;
  font-weight: 500;
  line-height: 42px;
}

.pricing-table[data-v-d998d3] {
  text-align: center;
  margin-bottom: 57px;
}

.pricing-table table[data-v-d998d3] {
  border-collapse: separate;
  border-spacing: 30px 0;
  display: flex;
  overflow-x: auto;
}

.pricing-table tbody[data-v-d998d3] {
  width: 100%;
  flex-grow: 1;
}

.pricing-table img[data-v-d998d3] {
  width: 36px;
  height: 36px;
  object-fit: contain;
}

.pricing-table tr:first-child th[data-v-d998d3]:not(:first-child), .pricing-table tr:first-child td[data-v-d998d3]:not(:first-child) {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.pricing-table tr:nth-child(2) td[data-v-d998d3]:not(:first-child) {
  text-align: center;
  color: #393939;
  font-size: 1.5em;
  font-weight: 400;
  line-height: 29px;
}

.pricing-table tr:last-child th[data-v-d998d3]:not(:first-child), .pricing-table tr:last-child td[data-v-d998d3]:not(:first-child) {
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.pricing-table th[data-v-d998d3] {
  text-align: center;
  color: #0717a3;
  padding: 30px;
  font-size: 1.6em;
  font-weight: 500;
  line-height: 24px;
}

.pricing-table th .light[data-v-d998d3] {
  text-align: center;
  font-size: .875em;
  font-weight: 300;
  line-height: 17px;
}

.pricing-table th[data-v-d998d3]:first-child {
  padding: 30px 30px 30px 0;
}

.pricing-table th[data-v-d998d3]:not(:first-child) {
  background: #faf9f9;
}

.pricing-table td[data-v-d998d3] {
  padding: 10px 30px;
}

.pricing-table td[data-v-d998d3]:first-child {
  text-align: left;
  color: #393939;
  padding: 10px 30px 10px 0;
  font-size: 1.125em;
  font-weight: 300;
  line-height: 22px;
}

.pricing-table td[data-v-d998d3]:not(:first-child) {
  text-align: center;
  color: #393939;
  background-color: #faf9f9;
  font-size: 1em;
  font-weight: 400;
  line-height: 17px;
}

.pricing-table td a[data-v-d998d3] {
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  background: #676767;
  border: 0;
  border-radius: 40px;
  margin-bottom: 20px;
  padding: 14px 48px;
  font-size: 1.25em;
  font-weight: 500;
  line-height: 24px;
  display: inline-block;
}

.info[data-v-d998d3] {
  color: #5c5c5c;
  flex-direction: column;
  gap: 12px;
  font-size: 1em;
  font-weight: 400;
  line-height: 19px;
  display: flex;
}

.b-500 b[data-v-d998d3] {
  font-weight: 500;
}

.container[data-v-3daae3] {
  max-width: 1310px;
  width: 100%;
  margin: 0 auto;
}

.footer[data-v-3daae3] {
  background: #faf9f9;
}

.wrapper[data-v-3daae3] {
  max-width: 1140px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  margin: 0 auto;
  padding: 90px 15px;
  display: flex;
}

@media screen and (max-width: 870px) {
  .wrapper[data-v-3daae3] {
    flex-direction: column;
  }
}

.text[data-v-3daae3] {
  text-align: center;
  color: #0717a3;
  font-size: 1.75em;
  font-weight: 500;
  line-height: 34px;
}

.action a[data-v-3daae3] {
  text-align: center;
  color: #fcfcff;
  background: #0717a3;
  border: 0;
  border-radius: 40px;
  padding: 20px 70px;
  font-size: 1.875em;
  font-weight: 500;
  line-height: 36px;
  display: inline-block;
}

/*# sourceMappingURL=index.2e39d5cb.css.map */
